//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-864:_5556,_3592,_4542,_824,_1840,_7772,_1788,_3924;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-864')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-864', "_5556,_3592,_4542,_824,_1840,_7772,_1788,_3924");
        }
      }catch (ex) {
        console.error(ex);
      }